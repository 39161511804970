/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Autocomplete, Divider, TextField } from '@mui/material';
import { Button } from '@components/Button';
import { ArrowRightIcon } from '@heroicons/react/24/outline';
import {
  Modal,
  ModalContainer,
  ModalContent,
  ModalHeader,
} from '@components/Modal';
import { BillingCycle } from './invoiceTypes';
import { useAuthHttp } from '../../hooks';
import {
  AUTHORISE_PAYMENT,
  GET_BANK_DETAILS,
  INVOICE_LIST,
} from '../../constants';
import { UserRoleService } from '../../services';
import { useAuth } from '../../context';
import { InvoicePaymentCard } from './InvoicePaymentCard';
import {
  buildPaymentReference,
  getBillingCycleCost,
  getBillingCycleVatCost,
} from './invoiceHelper';

export function PayInvoice() {
  const [searchParams] = useSearchParams();
  const billingCycleIds = searchParams.getAll('billingCycleId');
  const isReturningFromError = searchParams.getAll('error').length > 0;
  if (isReturningFromError) {
    // TODO: Show banner on first time return due to error
    console.log('Payment failed. Please try again');
  }
  searchParams.delete('error');
  const authHttp = useAuthHttp();
  const { user } = useAuth();
  const { isWarehouseClient } = UserRoleService(); // User should never see this page as non Shipper

  const [billingCycles, setBillingCycles] = useState<BillingCycle[]>([]);
  const [selectedBillingCycles, setSelectedBillingCycles] = useState<
    BillingCycle[]
  >([]);
  const [grandTotal, setGrandTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isAuthorising, setIsAuthorising] = useState(false);
  const [isConsentModalOpen, setIsConsentModalOpen] = useState(false);
  const [bank, setBank] = useState(null);
  const [paymentReference, setPaymentReference] = useState('');

  const setBillingCycleSelected = (
    billingCycle: BillingCycle,
    isSelecting: boolean
  ) => {
    if (isSelecting) {
      const index = billingCycles.indexOf(billingCycle);
      if (index > -1) {
        billingCycles.splice(index, 1);
        setSelectedBillingCycles([...selectedBillingCycles, billingCycle]);
      }
    } else {
      const index = selectedBillingCycles.indexOf(billingCycle);
      if (index > -1) {
        selectedBillingCycles.splice(index, 1);
        setBillingCycles([...billingCycles, billingCycle]);
      }
    }
  };

  const fetchInvoices = async () => {
    setIsLoading(true);
    try {
      const { data } = await authHttp.post(
        INVOICE_LIST,
        {
          statuses: isWarehouseClient(user) ? ['Finalised'] : '',
        },
        { headers: { 'Content-Type': 'application/json' } }
      );

      if (data) {
        if (billingCycleIds) {
          const preSelectedBillingCycles = data.billingCycles.filter(
            (b: BillingCycle) => billingCycleIds.find((i: string) => b.id === i)
          );
          if (preSelectedBillingCycles?.length > 0) {
            setSelectedBillingCycles(preSelectedBillingCycles);
            setBillingCycles(
              data.billingCycles.filter(
                (b: BillingCycle) =>
                  !billingCycleIds.find((i: string) => b.id === i)
              )
            );
          } else {
            setBillingCycles(data.billingCycles);
          }
        } else {
          setBillingCycles(data.billingCycles);
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const authorisePayment = async () => {
    setIsAuthorising(true);
    try {
      const { data } = await authHttp.post(
        AUTHORISE_PAYMENT,
        {
          billingCycleIds: selectedBillingCycles.map((b) => b.id),
          reference: paymentReference,
          baseURl: window.location.origin,
          redirectURl: 'profile/update-payment-consent',
        },
        { headers: { 'Content-Type': 'application/json' } }
      );
      if (data.paymentsAuthorisationUrl) {
        window.location.href = data.paymentsAuthorisationUrl;
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsAuthorising(false);
    }
  };

  const onClickPay = async () => {
    setIsConsentModalOpen(true);
  };

  const getCurrencySymbol = () => {
    return selectedBillingCycles.length > 0 &&
      selectedBillingCycles[0].invoice?.lines
      ? selectedBillingCycles[0].invoice.lines[0].currencySymbol
      : billingCycles.length > 0
      ? billingCycles[0].invoice.lines[0].currencySymbol
      : '£';
  };

  const fetchBankDetails = async () => {
    setIsLoading(true);
    try {
      const { data } = await authHttp.get(GET_BANK_DETAILS);

      if (data && data.bankAccount && data.bankAccount.bankName) {
        setBank(data.bankAccount.bankName);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedBillingCycles.length > 0) {
      const newGrandTotal = selectedBillingCycles.reduce(
        (runningTotal, billingCycle) =>
          runningTotal +
          getBillingCycleCost(billingCycle) +
          getBillingCycleVatCost(billingCycle),
        0
      );
      setGrandTotal(newGrandTotal);
      const updatedPaymentReference = buildPaymentReference(
        selectedBillingCycles
      );
      setPaymentReference(updatedPaymentReference);
    } else {
      setGrandTotal(0);
    }
  }, [billingCycles, selectedBillingCycles]);

  useEffect(() => {
    fetchInvoices();
    fetchBankDetails();
  }, []);

  return (
    <>
      <div className='flex' style={{ flexDirection: 'row' }}>
        <div className='leftHandSideWrapper mr-6'>
          <h2 className='font-bold my-6'>Payments due</h2>
          <div>
            {/* Selected billing cycles: */}
            {selectedBillingCycles.map(
              (billingCycle) =>
                billingCycle &&
                billingCycle.invoice && (
                  <InvoicePaymentCard
                    key={billingCycle.id}
                    billingCycle={billingCycle}
                    isSelected
                    onToggleSelected={setBillingCycleSelected}
                  />
                )
            )}
          </div>
          {selectedBillingCycles.length > 0 && billingCycles.length > 0 && (
            <div className='mt-6 mb-6'>
              <Divider />
            </div>
          )}
          <div>
            {/* Other billing cycles: */}
            {billingCycles.map((billingCycle) => (
              <InvoicePaymentCard
                key={billingCycle.id}
                billingCycle={billingCycle}
                onToggleSelected={setBillingCycleSelected}
              />
            ))}
          </div>
        </div>
        <Divider orientation='vertical' flexItem />
        <div className='rightHandSideWrapper ml-12'>
          <h2 className='font-bold my-6'>Payment options</h2>
          <div
            className='flex mt-8 p-8 bg-white rounded-lg sm:p-6 
        shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg'
            style={{ flexDirection: 'column' }}
          >
            <div>
              <div
                className='font-bold'
                style={{ minWidth: '40rem', textAlign: 'left' }}
              >
                Grand Total: {getCurrencySymbol()}
                {grandTotal.toFixed(2)}
              </div>
            </div>
            <div className='my-8'>
              <Divider className='my-8' />
            </div>
            <Button
              variant='primary'
              size='lg'
              onClick={onClickPay}
              disabled={
                isLoading ||
                isAuthorising ||
                !bank ||
                selectedBillingCycles.length === 0
              }
            >
              {isLoading
                ? 'Loading...'
                : isAuthorising
                ? 'Authorising...'
                : selectedBillingCycles.length === 0
                ? 'Select invoice(s)'
                : 'Pay with Yapily'}
              {!isLoading && bank && selectedBillingCycles.length !== 0 && (
                <ArrowRightIcon className='inline h-10 w-10 shrink-0' />
              )}
            </Button>
          </div>
        </div>
      </div>
      {selectedBillingCycles.length > 0 && (
        <Modal open={isConsentModalOpen} onOpenChange={setIsConsentModalOpen}>
          <ModalContainer>
            <ModalHeader title='Yapily Connect' />
            <ModalContent>
              <div className='space-y-4'>
                <p>
                  We have partnered with Yapily Connect to securely initiate
                  payment from your account at {bank}.
                </p>
                <div>
                  <p>Payment Total</p>
                  <p>
                    {getCurrencySymbol()}
                    {grandTotal.toFixed(2)}
                  </p>
                </div>
                <div>
                  <p>Payee Details</p>
                  <p>Payee Name: VCL Trading Europe Ltd</p>
                  <div>
                    <p>Payee Account Identification</p>
                    <p>Account Number: 32163827</p>
                    <p>Sort Code: 560014</p>
                    <p>Payment Reference: {paymentReference}</p>{' '}
                  </div>
                </div>
                <p>
                  By using the service, you agree to Yapily Connect initiating
                  this payment and its
                  <a
                    href='https://www.yapily.com/legal/end-user-terms?_gl=1*6m3mg2*_gcl_au*NzU2MzY1NTYxLjE3MzE0OTQ1MTg.'
                    target='_blank'
                    rel='noopener noreferrer'
                    className='text-blue-500 visited:text-purple-500'
                  >
                    &nbsp;Terms & Conditions&nbsp;
                  </a>
                  and
                  <a
                    href='https://www.yapily.com/legal/privacy-policy?_gl=1*6m3mg2*_gcl_au*NzU2MzY1NTYxLjE3MzE0OTQ1MTg.'
                    target='_blank'
                    rel='noopener noreferrer'
                    className='text-blue-500 visited:text-purple-500'
                  >
                    &nbsp;Privacy Notice
                  </a>
                  .
                </p>
                <div
                  className='d-flex flex flex-row gap-x-2 justify-content-end'
                  style={{ justifyContent: 'end' }}
                >
                  <Button
                    variant='primary'
                    size='lg'
                    onClick={() => setIsConsentModalOpen(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant='primary'
                    size='lg'
                    onClick={() => authorisePayment()}
                    disabled={isAuthorising}
                  >
                    {isAuthorising ? 'Submitting...' : 'Confirm'}
                  </Button>
                </div>
              </div>
            </ModalContent>
          </ModalContainer>
        </Modal>
      )}
    </>
  );
}
