import { Button } from '@components/Button';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Divider } from '@mui/material';
import { ArrowRightIcon } from '@heroicons/react/24/outline';
import { BillingCycle } from './invoiceTypes';

export function InvoicePaymentDetails({
  isLsp,
  hasConfirmed,
  billingCycle,
  invoiceNotes,
  dueDate,
  isConfirming,
  handleConfirmInvoice,
  setHasPaid,
}: {
  isLsp?: boolean;
  hasConfirmed?: boolean;
  billingCycle: BillingCycle;
  invoiceNotes: string;
  dueDate: string;
  isConfirming?: boolean;
  handleConfirmInvoice?: () => void;
  setHasPaid: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const navigate = useNavigate();
  const createdAt = moment(billingCycle.invoice.createdAt);
  const today = moment();
  const elapsedDays = today.diff(createdAt, 'days');
  const hasPaid = billingCycle.invoice.status === 'Paid';

  return (
    <>
      <div className='mt-8'>
        <div className='grid grid-cols-2 gap-4'>
          <div className='space-y-4'>
            <div>
              Beneficiary Bank:{' '}
              {isLsp ? billingCycle.lspBankName : 'VCL Trading Europe Ltd'}
            </div>
            <div>
              Sort Code:{' '}
              {isLsp
                ? billingCycle.lspBankSortCode
                : import.meta.env.VITE_VCL_SORT_CODE}
            </div>
            <div>
              Account Number:{' '}
              {isLsp
                ? billingCycle.lspBankAccountNumber
                : import.meta.env.VITE_VCL_ACCOUNT_NUMBER}
            </div>
            <div>
              BIC/SWIFT:{' '}
              {isLsp
                ? billingCycle.lspBankSwiftCode
                : import.meta.env.VITE_VCL_BIC_SWIFT}
            </div>
            <div>
              IBAN:{' '}
              {isLsp ? billingCycle.lspBankIban : import.meta.env.VITE_VCL_IBAN}
            </div>
          </div>
          <div className='space-y-4'>
            {hasConfirmed && !hasPaid && (
              <div>
                Payment due date: {moment(dueDate).format('DD/MM/YYYY')}
              </div>
            )}
            {hasPaid && billingCycle.invoice.payments.length > 0 && (
              <>
                {billingCycle.invoice.payments.map((payment) => {
                  return (
                    <>
                      <div>
                        Payment date:{' '}
                        {moment(payment.createdAt).format('DD/MM/YYYY')}
                      </div>
                      <div>
                        Transaction reference: {payment.paymentReference}
                      </div>
                    </>
                  );
                })}
                <>
                  <div>Payments:</div>
                  {billingCycle.invoice.payments.map((payment) => {
                    return (
                      <div
                        key={payment.id}
                        className='mt-8 px-8 py-4 bg-white rounded-lg sm:px-6 sm:py-4
                        shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg'
                      >
                        <div
                          className='flex flex-row mb-2'
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            maxWidth: '50rem',
                          }}
                        >
                          <div>{payment.paymentReference}</div>
                          <div className='mr-4'>
                            {payment.currencySymbol}
                            {payment.amount.toFixed(2)}
                          </div>
                          <Divider />
                          <div className='mr-4'>
                            {moment(payment.createdAt).format('DD/MM/YYYY')}
                          </div>
                          <Divider />
                        </div>
                        <Divider variant='inset' textAlign='left' />
                        <div
                          className='flex flex-row mt-2'
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            // maxWidth: '50rem',
                          }}
                        >
                          <div className='mt-2'>{payment.status}</div>
                          <Button
                            onClick={() => navigate(`/payment/${payment.id}`)}
                            style={{
                              marginRight: '4px',
                              // padding: '0',
                              // background: 'none',
                            }}
                          >
                            View
                            <ArrowRightIcon className='inline h-10 w-10 shrink-0' />
                          </Button>
                        </div>
                      </div>
                    );
                  })}
                </>
              </>
            )}
            {!hasConfirmed && isLsp && (
              <Button
                variant='primary'
                size='lg'
                onClick={handleConfirmInvoice}
                disabled={isConfirming}
              >
                {isConfirming ? 'Confirming...' : 'Confirm'}
              </Button>
            )}
            {hasConfirmed && !hasPaid && isLsp && (
              <Button
                variant='primary'
                size='lg'
                onClick={() => {
                  setHasPaid(true);
                }}
                disabled={false} // Set this to true when demo is over
              >
                Finance
              </Button>
            )}
            {!hasPaid && !isLsp && (
              <Button
                variant='primary'
                size='lg'
                onClick={() => {
                  navigate(`/invoice/pay?billingCycleId=${billingCycle.id}`);
                }}
              >
                Pay Invoice
              </Button>
            )}
            {!hasConfirmed && isLsp && (
              <div>Elapsed from PI issue date: {elapsedDays} days</div>
            )}
          </div>
        </div>
      </div>
      {!hasConfirmed && isLsp && (
        <>
          <div className='mt-8'>Invoice note(s):</div>
          <div>
            <textarea
              id='comments'
              name='comments'
              rows={4}
              className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-floxBlue focus:ring-floxBlue sm:text-sm'
              placeholder='Enter any additional invoice notes here...'
            />
          </div>
        </>
      )}
      {hasConfirmed && invoiceNotes && invoiceNotes.length > 0 && (
        <>
          <div className='mt-8'>Invoice note(s):</div>
          <div>{invoiceNotes}</div>
        </>
      )}
    </>
  );
}
